import React, { FunctionComponent } from "react";
import * as styles from "./container.module.scss";

type ContainerModel = {
  backgroundColor?: "gray" | "white";
  id?: string;
  role: string;
  children: any;
};

const Container: FunctionComponent<ContainerModel> = ({ children, backgroundColor, id, role }) => {
  return (
    <div className={backgroundColor} id={id} role={role}>
      <div className={styles.root}>{children}</div>
    </div>
  );
};

export default Container;
