import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";

export interface VerifyingDataPageModel {
  title: string;
  _rawDescription: any;
  _rawTableTest: any;
}

export const query = graphql`
  query VerifyingDataPageQuery {
    site: sanityVerifyingData {
      _rawDescription
      title
      _rawTableTest
    }
  }
`;

const VerifyingDataPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: VerifyingDataPageModel = (data || {}).site;

  return (
    <Layout>
      <SEO title={site.title} />
      <Container role="main">
        <h1>{site.title}</h1>
        {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
        {site._rawTableTest && <BlockContent blocks={site._rawTableTest || []} />}
        <div dangerouslySetInnerHTML={site._rawTableTest}> </div>
      </Container>
    </Layout>
  );
};

export default VerifyingDataPage;
